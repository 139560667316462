import React, { Component, Fragment } from 'react'
import { Link, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import logo from '../images/saba logo.png'
import icon from '../images/menu_icon.png'
import colors from '../styles/colors'
import { NavbarToggler, NavbarBrand } from 'reactstrap';
// import { NavbarBrand } from 'reactstrap'
import Menu from './menu'

const StyledNavbar2 = styled.div`
  margin: 0px auto;
  width: 100%;
  /* padding: 12px 24px 12px 24px; */
  position: fixed;
  display: flex;
  top: 0;
  background-color: white;
  align-items: center;
  z-index: 2;
  /* box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.25); */
  /* background: #f5f5f5; */
`

const Hamburger =styled.img`
max-width: 45px;
`

const StyledNavbar = styled.div`
  margin: 0px auto;
  width: 100%;
  padding: 12px 24px 12px 24px;
  /* position: fixed; */
  display: flex;
  top: 0;
  background-color: white;
  align-items: center;
  z-index: 2;
  max-width: 80rem;

  a {
    color: ${colors.font.dark} !important;
  }

  .navbar-brand {
    font-size: 3rem !important;
    font-weight: 900; // bold
    a {
      color: #0d4ca1 !important;
    }
  }
  .navbar-toggler {
    border-color: white !important;
    path {
      fill: ${colors.font.dark};
    }
  }

  @media (max-width: 885px) {
    .navbar-collapse {
      display: none;
    }
    a {
      color: #fff !important;
    }
  }
`

const NavbarBrandLink = styled(Link)`
  // &:after {
  //   display: table-cell;
  // }
`
const StyledNavBarBrand = styled(NavbarBrand)`
  width: 200px;
  margin-right: 9px !important;
  overflow: hidden;
  transition: all 0.2s;
  // padding-top: px;
  &.smaller {
    width: 200px;
  }
`
const StyledNavbarBrandName = styled(NavbarBrand)`
  a:hover {
    text-decoration: none;
  }
`

const FlexGrow = styled.div`
  flex: 1;
`
const NavItem = styled.div`
  margin: auto 10px;
  a {
    color: ${colors.font.dark} !important;
    text-decoration: none !important;
    font-size: 14px !important;
    font-weight: 500; // medium
  }
  @media (max-width: 885px) {
    display: none;
  }
`

const Dropdown = styled.div`
  display: none;
  position: absolute;
  top: 66px;
  left: 0;
  width: 100%;
  background-color: #10435e;
  padding: 10px 30px 15px 30px;
  z-index: 2;

  @media (max-width: 885px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
  }
`

const DDNavItem = styled.div`
  margin: 25px 20px 15px 20px;
  // border-bottom: 2px solid ${colors.border.lighter};
`

const StyledNavbarToggler = styled.div`
  display: none;
  margin: 0 0 0 30px;
  height: 42px;

  @media (max-width: 885px) {
    display: flex !important;
    align-items: center;
    color: #333;
  }
`

const StyledNav = styled.div`
  display: flex;
  @media (max-width: 885px) {
    display: none;
  }
`

const ActionButton = styled.div`
  background-color: ${colors.theme.main};
  border-radius: 3px;
  transition: all 0.2s;
  padding: 8px 15px;
  margin: 0 5px;
  border-radius: 3px;
  display: flex;
  align-items: center;

  a {
    color: white !important;
    font-size: 14px !important;
    font-weight: 500; // medium
    transition: color 0.2s;
      text-decoration: none !important;
    &:hover {
      text-decoration: none;
    }
  }

  &:hover {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 885px) {
    display: visible;
  }
  @media (max-width: 630px) {
    display: none;
  }
`

const ActionButton2 = styled.div`
  background-color: ${colors.theme.main};
  border-radius: 3px;
  transition: all 0.2s;
  padding: 8px 15px;
  margin: 0 5px;
  border-radius: 3px;
  display: flex;
  align-items: center;

  a {
    color: white !important;
    font-size: 14px !important;
    font-weight: 500; // medium
    transition: color 0.2s;
    text-decoration: none !important;
    &:hover {
      text-decoration: none;
    }
  }

  &:hover {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 885px) {
    display: visible;
    margin: 0;
  }
  @media (max-width: 630px) {
    display: visible;
  }
`

const ActionButtonOutline = styled(ActionButton)`
  border: 2px solid ${colors.theme.accent};
  background-color: white;
  a {
    color: ${colors.font.dark} !important;
    transition: color 0.2s;
  }
  // @media (max-width: 768px) {
  //   display: block;
  // }
  // @media (max-width: 630px) {
  //   display: none;
  // }
`

class Header extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.resizeHeaderOnScroll)
  }
  resizeHeaderOnScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 100,
      headerEl = document.getElementById('header-brand')

    if (distanceY > shrinkOn) {
      headerEl.classList.add('smaller')
    } else {
      headerEl.classList.remove('smaller')
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    const { siteTitle } = this.props
    // console.log(this.state.isOpen)

    return (
      <StaticQuery
        query={graphql`
          query logoQuery {
            logo: file(relativePath: { eq: "saba logo.png" }) {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={(data) => {
          console.log(data)
          return (
            <Fragment>
              <StyledNavbar2 color="#fff" light expand="md">
                <StyledNavbar color="#f5f5f5" light expand="md">
                  <StyledNavBarBrand id="header-brand">
                    <Link to="/">
                      <img src={logo} alt="Logo" />{' '}
                    </Link>
                  </StyledNavBarBrand>
                  {/* <StyledNavbarBrandName id="header-brand">
                    <Link to="/">{siteTitle}</Link>
                  </StyledNavbarBrandName> */}
                  {/*<NavbarBrand href="/">{siteTitle}</NavbarBrand>*/}
                  <FlexGrow />
                  <StyledNavbarToggler onClick={this.toggle}>
                      <Hamburger src={icon} alt="Hamburger Menu Icon" />
                  </StyledNavbarToggler>
                  <Dropdown isOpen={this.state.isOpen}>
                    {/*<DDNavItem>*/}
                    {/*  <Link to="/">Home</Link>*/}
                    {/*</DDNavItem>*/}
                    {/*<DDNavItem>*/}
                    {/*  <Link to="/about/">About</Link>*/}
                    {/*</DDNavItem>*/}
                    {/*<DDNavItem>*/}
                    {/*<Link to="/covid19/">Covid-19 Resources</Link>*/}
                    {/*</DDNavItem>*/}
                    {/*<DDNavItem>*/}
                    {/*  <Link to="/faq/">FAQ</Link>*/}
                    {/*</DDNavItem>*/}
                    {/*<DDNavItem>*/}
                    {/*  <Link to="/contact/">Contact Us</Link>*/}
                    {/*</DDNavItem>*/}
                    <Menu />
                    {/*<DDNavItem>*/}
                    {/*<Link to="/referral/">Refer & Earn</Link>*/}
                    {/*</DDNavItem>*/}
                  </Dropdown>
                  <StyledNav navbar>
                    {/*<NavItem>*/}
                    {/*  <Link to="/">Home</Link>*/}
                    {/*</NavItem>*/}
                    {/*<NavItem>*/}
                    {/*  <Link to="/about/">About</Link>*/}
                    {/*</NavItem>*/}
                    {/*<NavItem>*/}
                    {/*  <Link to="/covid19/">Covid-19 Resources</Link>*/}
                    {/*</NavItem>*/}
                    {/*<NavItem>*/}
                    {/*  <Link to="/faq">FAQ</Link>*/}
                    {/*</NavItem>*/}
                    <Menu />
                    <ActionButton>
                      <Link to="/impact-survey/">Register</Link>
                    </ActionButton>
                    {/*<ActionButtonOutline>*/}
                    {/*<Link to="/referral">Refer & Earn</Link>*/}
                    {/*</ActionButtonOutline>*/}
                  </StyledNav>
                </StyledNavbar>
              </StyledNavbar2>
            </Fragment>
          )
        }}
      />
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
