import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import MenuItem from './menu-item'
import styled from 'styled-components'
import colors from '../styles/colors'

const Dropdown = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 885px) {
    flex-direction: column;
  }

  a {
    margin: 0.25 auto;
  }
`

/**
 * Define MenuItem fragment and get all primary menu items.
 */
const MENU_QUERY = graphql`
  fragment MenuItem on WPGraphQL_MenuItem {
    id
    label
    url
    title
    target
  }

  query GETMAINMENU {
    wpgraphql {
      menuItems(where: { location: PRIMARY }) {
        nodes {
          ...MenuItem
          childItems {
            nodes {
              ...MenuItem
              childItems {
                nodes {
                  ...MenuItem
                }
              }
            }
          }
        }
      }
      generalSettings {
        url
      }
    }
  }
`

const Menu = () => {
  return (
    <StaticQuery
      query={MENU_QUERY}
      render={data => {
        if (data.wpgraphql.menuItems) {
          const menuItems = data.wpgraphql.menuItems.nodes
          const wordPressUrl = data.wpgraphql.generalSettings.url

          return (
            <Dropdown>
              {menuItems &&
                menuItems.map(menuItem => (
                  <MenuItem
                    key={menuItem.id}
                    menuItem={menuItem}
                    wordPressUrl={wordPressUrl}
                  />
                ))}
            </Dropdown>
          )
        }
        return null
      }}
    />
  )
}

export default Menu
