import React from "react"
import { CreateLocalLink } from "../utils"
import UniversalLink from "./universal-link"

const MenuItem = ({ menuItem, wordPressUrl }) => {
  return (
    <UniversalLink style={{ textDecoration: "none !important", marginRight: "20px", padding: "10px 0" }}
                   to={CreateLocalLink(menuItem, wordPressUrl)}>
      {menuItem.label}
    </UniversalLink>
  )
}

export default MenuItem