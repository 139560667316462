import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {StaticQuery, graphql} from 'gatsby';

function SEO({ description, lang, meta, keywords, image: metaImage, title, pathname }) {
// function SEO({description, lang, meta, keywords, title}) {
  return (
      <StaticQuery
          query={detailsQuery}
          render={data => {
            const metaDescription =
                description || data.site.siteMetadata.description;
            const image =
              metaImage && metaImage.src
                ? `${data.site.siteMetadata.siteUrl}${metaImage.src}`
                : null
            const canonical = pathname ? `${data.site.siteMetadata.siteUrl}${pathname}` : null
            return (
                <Helmet
                    htmlAttributes={{
                      lang,
                    }}
                    title={title}
                    titleTemplate={`%s | ${data.site.siteMetadata.title}`}
                    link={
                      canonical
                        ? [
                          {
                            rel: "canonical",
                            href: canonical,
                          },
                        ]
                        : []
                    }
                    meta={[
                      {
                        name: `description`,
                        content: metaDescription,
                      },
                      {
                        property: `og:title`,
                        content: title,
                      },
                      {
                        property: `og:description`,
                        content: metaDescription,
                      },
                      {
                        property: `og:type`,
                        content: `website`,
                      },
                      // {
                      //   name: `twitter:card`,
                      //   content: `summary`,
                      // },
                      {
                        name: `twitter:creator`,
                        content: data.site.siteMetadata.creator,
                      },
                      {
                        name: `twitter:title`,
                        content: title,
                      },
                      {
                        name: `twitter:description`,
                        content: metaDescription,
                      },
                    ].concat(
                        keywords.length > 0
                            ? {
                              name: `keywords`,
                              content: keywords.join(`, `),
                            }
                            : [],
                    ).concat(
                      metaImage
                        ? [
                          {
                            property: "og:image",
                            content: data.site.siteMetadata.socialImg,
                          },
                          {
                            property: "og:image:width",
                            content: metaImage.width,
                          },
                          {
                            property: "og:image:height",
                            content: metaImage.height,
                          },
                          {
                            name: "twitter:card",
                            content:  data.site.siteMetadata.socialImg,
                          },
                        ]
                        : [
                          {
                            name: "twitter:card",
                            content:  data.site.siteMetadata.socialImg,
                          },
                        ]
                    )
                      .concat(meta)}
                />
            );
          }}
      />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
