import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import '../styles/fonts.scss'
import '../styles/styles.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import SuscribeForm from './subscribe-form'

const Body = styled.div`
  padding: 0 24px 15px;
  /* max-width: 1540px; */
  margin: auto;
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  /* background: #efefef; */
  width: 100%;
  
  a {
  text-decoration: underline;
  }

  @media (max-width: 767px) {
    padding: 0 12px;
    margin-top: 60px;
    width: 100%;
  }
`

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        officialTitle
      }
    }
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={query}
    render={({ site }) => (
      <Fragment>
        <Header siteTitle={site.siteMetadata.title} />
        <Body>{children}</Body>
        <SuscribeForm />
        <Footer company={site.siteMetadata.officialTitle} />
      </Fragment>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
