import React, { Component } from 'react'
import axios from 'axios'
// import './index.css'
import styled from 'styled-components'

const GOOGLE_FORM_CONTACT_NAME = 'entry.576016263'
const GOOGLE_FORM_EMAIL_ID = 'emailAddress'

const CORS_PROXY = 'https://cors-anywhere.herokuapp.com/'
const GOOGLE_FORM_ACTION =
  'https://docs.google.com/forms/u/0/d/e/1FAIpQLSeuwmgYhpMGZ63M5deOpdjdTHQdqC6oKa4b5Sm42sj4o5shgg/formResponse'

const Container1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    margin-bottom: 1.5rem;
  }
`
const Row1 = styled.div`
  /* max-width: 80rem; */
  max-width: 55rem;
  width: 100%;
  margin: 3rem auto;

  @media (max-width: 80rem) {
    /* margin-left: 12px;
    margin-right: 12px; */
    margin: 0rem auto 0rem;
  }
`

class SuscribeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contact_name: '',
      contact_email: '',
      showForm: true,
      sendingMessage: false,
      messageSent: false,
      messageError: false,
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({
      sendingMessage: true,
    })
    this.sendMessage()
  }

  handleFormToggle = () => {
    this.setState((prevState) => {
      const { showForm } = prevState
      return {
        showForm: !showForm,
      }
    })
  }

  handleReturnButton = () => {
    this.setState({
      showForm: false,
      messageSent: false,
      messageError: false,
    })
  }

  sendMessage = () => {
    const formData = new FormData()
    formData.append(GOOGLE_FORM_CONTACT_NAME, this.state.contact_name)
    formData.append(GOOGLE_FORM_EMAIL_ID, this.state.contact_email)

    axios
      .post(CORS_PROXY + GOOGLE_FORM_ACTION, formData)
      .then(() => {
        this.setState({
          messageSent: true,
          sendingMessage: false,
          contact_name: '',
          contact_email: '',
        })
      })
      .catch(() => {
        this.setState({
          messageError: true,
          sendingMessage: false,
        })
      })
  }

  returnButton = () => (
    <button
      id="return-button"
      className="btn btn-default btn-xs"
      onClick={this.handleReturnButton}
    >
      Return
    </button>
  )

  render() {
    if (this.state.sendingMessage) {
      return (
        <Row1>
          <div className="emailsuscribe">
            <Container1>
              <div className="container">
                <h3 className="margin-bottom">
                  <b>Signing up...</b>
                </h3>
              </div>{' '}
            </Container1>
          </div>
        </Row1>
      )
    }

    if (this.state.messageSent) {
      return (
        <React.Fragment>
          <Row1>
            <div className="emailsuscribe">
              <Container1>
                <div className="container msg-container">
                  {/* <div className="success-message"> */}
                  <h3 className="margin-bottom">
                    <b>You have successfully signed up!</b>
                  </h3>
                  <p>
                    Look out for Somali American Business Association updates in
                    your email inbox.
                  </p>
                  {/* </div> */}
                  {/* {this.returnButton()} */}
                </div>
              </Container1>
            </div>
          </Row1>
        </React.Fragment>
      )
    }

    if (this.state.messageError) {
      return (
        <React.Fragment>
          <Row1>
            <div className="emailsuscribe">
              <Container1>
                <div className="container msg-container">
                  {/* <div className="error-message"> */}
                  <h3 className="margin-bottom">
                    <b>Error!</b>
                  </h3>
                  <p>
                    You were not able to successfully sign up. Please confirm
                    you entered the right email address and try again.
                  </p>{' '}
                  {/* </div> */}
                  {this.returnButton()}
                </div>
              </Container1>
            </div>
          </Row1>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Row1>
          <div className="emailsuscribe">
            <Container1>
              <div>
                <h2 className="margin-bottom">Join our Community</h2>
                <p>
                  Sign up to get the latest about Somali American Business
                  Association events, services, and news updates.
                </p>
              </div>
              <form onSubmit={this.handleSubmit} className="form-inline">
                <div className="input-group ig1">
                  <input
                    type="contact_name"
                    name="contact_name"
                    id="contact_name"
                    className="form-control"
                    placeholder="Your Name"
                    size="35"
                    value={this.state.contact_name}
                    onChange={this.handleChange}
                    required
                  />
                  <input
                    type="contact_email"
                    name="contact_email"
                    id="contact_email"
                    className="form-control"
                    placeholder="Email Address"
                    size="35"
                    value={this.state.contact_email}
                    onChange={this.handleChange}
                    required
                  />

                  {/* <input
                  type="email"
                  className="form-control"
                  placeholder="email"
                  size="50"
                  required
                /> */}
                </div>
                <div className="input-group-btn ig2">
                  <button type="submit" className="btn btn-danger">
                    Sign Up
                  </button>
                </div>
              </form>
            </Container1>
          </div>
        </Row1>
      </React.Fragment>
    )
  }
}

export default SuscribeForm
