import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import colors from '../styles/colors'

const StyledFooter = styled.div`
  margin: 0px auto;
  padding: 25px 24px !important;
  border-top: 0px solid #767676;
  /* max-width: 1540px; */
  /* max-width: 80rem; */
  /* background: #0094d9; */

  // @media (max-width: 767px) {
  //   padding: 55px 24px !important;
  // }
`

const Framed = styled.div`
  max-width: 80rem;
  width: 100%;
  padding: 0 24px;
  margin: auto;
  text-align: center;
  color: #efefef;
  @media (max-width: 767px) {
    padding: 0;
  }
`

const Footer = ({ company }) => {
  return (
    <StyledFooter>
      <Framed>
        Copyright © 2020 <a href="/">{company}</a>. Built by{' '}
        <a href="http://www.bsmg.co">BSMG Digital</a>
      </Framed>
    </StyledFooter>
  )
}

Footer.propTypes = {
  company: PropTypes.string.isRequired,
}

export default Footer
