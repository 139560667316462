export default {
  theme: {
    // main: '#F16664',
    main: '#FBA62F',
    secondary: '#009CDE',
    third: '#15587B',
    fourth: '#479761',
    lightaccent: '#9CD6D2',
    accent: '#fff000',
    darkaccent: '#007D8C',
    // lighter
    // lightmain: '#F48583',
  },
  font: {
    dark: '#484848',
    light: '#767676',
  },
  border: {
    dark: '#484848',
    light: '#767676',
    lighter: 'rgba(0,0,0,0.2)',
  },
}
